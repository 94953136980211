import React, {useState} from 'react'
import '../index.css';
import Editor from "./Editor";
import useLocalStorage from "../hooks/useLocalStorage";


function App() {
    const [script, setScript] = useLocalStorage('script', 'print("Hello World");');
    const [srcDoc, setSrcDoc] = useState('');
    const [resultText, setResultText] = useState('');
    const [syntaxError, setSyntaxError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [resultTextCss, setResultTextCss] = useState('');
    let errorDiv = <div className="error-message">{`${errorMessage}`}</div>;
    let output = [];

    const CORRECTRESULT = ['1', '2', 'Fizz', '4', 'Buzz', 'Fizz', '7', '8', 'Fizz', 'Buzz', '11', 'Fizz', '13', '14', 'FizzBuzz', '16', '17', 'Fizz', '19', 'Buzz', 'Fizz', '22', '23', 'Fizz', 'Buzz', '26', 'Fizz', '28', '29', 'FizzBuzz', '31', '32', 'Fizz', '34', 'Buzz', 'Fizz', '37', '38', 'Fizz', 'Buzz', '41', 'Fizz', '43', '44', 'FizzBuzz', '46', '47', 'Fizz', '49', 'Buzz', 'Fizz', '52', '53', 'Fizz', 'Buzz', '56', 'Fizz', '58', '59', 'FizzBuzz', '61', '62', 'Fizz', '64', 'Buzz', 'Fizz', '67', '68', 'Fizz', 'Buzz', '71', 'Fizz', '73', '74', 'FizzBuzz', '76', '77', 'Fizz', '79', 'Buzz', 'Fizz', '82', '83', 'Fizz', 'Buzz', '86', 'Fizz', '88', '89', 'FizzBuzz', '91', '92', 'Fizz', '94', 'Buzz', 'Fizz', '97', '98', 'Fizz', 'Buzz',];

    function executeScript() {
        output = [];
        setSyntaxError(false);
        setErrorMessage('');
        setResultText('');
        try {
            const scripFunction = Function(`
             let output = [];
             function print(text) {
                output.push(text.toString());
            }
            ${script}
            return output;
            `);
            output = scripFunction();
            updateSrcDoc();
            checkPassed();
        } catch (e) {
            if (e instanceof Error) {
                setSyntaxError(true);
                setResultTextCss('');
                setResultText('Ergebnis kann nicht ausgewertet werden, da dein Code noch einen oder mehrere Syntaxfehler hat:');
                setErrorMessage(e);
            }
        }
    }

    function checkPassed() {
        for (let i = 0; i < CORRECTRESULT.length; i++) {
            if (CORRECTRESULT[i] !== output[i]) {
                setResultTextCss('error-message');
                setResultText('Deine Lösung ist falsch. Erster Fehler tritt an der Stelle: ' + (i + 1) + ' auf, hier wurde: ' + CORRECTRESULT[i] + ' erwartet, dein Ergebnis ist allerdings: ' + output[i]);
                return;
            }
        }
        setResultTextCss('success-result');
        setResultText("Deine Lösung ist korrekt");
    }

    function updateSrcDoc() {
        let srcDoc = '';
        output.forEach(text => srcDoc += text + '<br />');
        setSrcDoc(srcDoc);
    }

    return (
        <>
            <div className="pane top-pane" data-testid="editor">
                <Editor
                    data-testid="editor"
                    language="javascript"
                    value={script}
                    onChange={setScript}
                ></Editor>
            </div>
            <button className="submit-code-button" onClick={executeScript} data-testid="button"> ▶ Code ausführen
            </button>
            <div className="ergebnis-text" data-testid="ergebnis">
                Ergebnis: <br/>
                <div className={resultTextCss}> {resultText} </div>
                {syntaxError ? errorDiv : null}
            </div>
            <div className="result-frame">
                <iframe
                    title="console-output"
                    srcDoc={srcDoc}
                    sandbox="allow-scripts"
                    width="100%"
                    height="100%"
                />
            </div>
        </>
    );
}

export default App;
