import React from 'react';
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import 'codemirror/mode/javascript/javascript'
import {Controlled as ControlledEditor} from 'react-codemirror2-react-17'


export default function Editor(properties) {
    const {
        language,
        value,
        onChange,
    } = properties

    function handleChange(editor, data, value){
        onChange(value);
    }

    return (
        <div className="editor-container">
            <div className="editor-title">
                JavaScript:
            </div>
            <ControlledEditor
                onBeforeChange={handleChange}
                value={value}
                className="code-mirror-wrapper"
                options={{
                    lineWrapping: true,
                    lint: true,
                    mode: language,
                    theme: 'material',
                    lineNumbers: true,
                }}
            />
        </div>
    )
}