import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import logo from './assets/Logo_custom_code_small.png';

ReactDOM.render(
    <React.StrictMode>
        <div className="logoBanner">
            <img src={logo} className="logo"/>
            <h2> Mini-Challenge "FizzBuzz" </h2>
        </div>
        <div className="app-container">
            <div className="aufgaben-container">
                Hier kannst du den FizzBuzz-Algorithmus implementieren. Was ist der Fizz-Buzz-Algorithmus? Hier ist die fachliche Beschreibung:
                <br/><br/>
                1) Schreibe ein Programm, das die Zahlen von 1 bis 100 ausgibt <br/>
                2) Für Vielfache von drei gib statt der Zahl „Fizz“ aus <br/>
                3) Für Vielfache von fünf „Buzz“ <br/>
                4) Für Zahlen, die Vielfache von drei und fünf sind, gib statt der Zahl „FizzBuzz“ aus<br/>
                <br/>
                Um die Implementierung zu vereinfachen, gibt es eine „print()“-Funktion, die du für die Ausgabe verwenden kannst. Wenn du etwas implementiert hast kannst deinen Code jederzeit ausführen und bekommst dann die Ausgabe deines Algorithmus angezeigt.

            </div>
            <App/>
        </div>
    </React.StrictMode>,
    document.getElementById('root')
);